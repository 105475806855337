import { db } from '@/config/firebase';

async function getAllIndustries() {
    let industriesObj = {};

    const dbRef = db.collection('industries');
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let industryObj = doc.data();
        industriesObj[id] = {
            id: id,
            ...industryObj
        }
    });

    return industriesObj;
}

export default {
    getAllIndustries
}