<template>
	<b-modal
		:title="`${companyName} Logo`"
		id="company-logo-image-view"
		:no-close-on-backdrop="true"
		size="md"
		:hide-footer="true"
		centered
	>
		<b-row>
			<b-col sm="12">
				<div align="center">
					<b-img
						v-img-orientation-changer
						:src="imageDetails.imageLink"
						alt="Responsive image"
						class="img-responsive"
						fluid
					/>
				</div>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
	name: 'CompanyLogoImageView',
	data() {
		return {
			imageDetails: {},
		};
	},
	computed: {
		companyName() {
			if (!_.isEmpty(this.imageDetails) && !_.isEmpty(this.imageDetails.company.name)) {
				return this.imageDetails.company.name;
			}
			return '';
		},
	},
	mounted() {
		EventBus.$on('onSelCompanyLogoImageView', (imageDetails) => {
			this.imageDetails = imageDetails;
		});
	},
	beforeDestroy() {
		EventBus.$off('onSelCompanyLogoImageView');
	},
};
</script>

<style scoped>
.thumbnail {
	position: relative;
}
.caption {
	position: absolute;
	bottom: 0px;
	left: -10px;
	width: 100%;
}
.strokeme {
	color: white;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
		1px 1px 0 #000;
}
</style>