import config from '@/config/env-constants';

function getDefaultCompanyObj() {
    return {
        name: '',
        description: '',
        type: null,
        industry: null,
        industryOthers: null,
        hasParentCompany: false,
        parentCompany: '',
        parentCompanyId: '',
        isActive: 'true',

        address: '',
        geoaddress: {
            latitude: null,
            longitude: null,
        },
        region: '',
        area: '',

        contactPerson: '',
        contactNo: '',
        emailAddress: '',

        // subscriptions details
        brand: '',
        branchCode: '',
        noOfStorageLocations: 0,
        noOfInactiveNodes: 0,
        noOfUsers: 0,
        has2WayAuth: false,
        camera: { ...config.cameraDefaultValues },

        permissions: { ...config.permissionsDefaultValues },
        companyLogo: {},

        dateCreated: '',
        createdBy: '',
        dateUpdated: '',
        updatedBy: '',
    };
}

function cleanupFields(company) {
    let cleanedObj = { ...company };

    delete cleanedObj['status'];
    delete cleanedObj['Date Created'];
    delete cleanedObj['Date Updated'];
    delete cleanedObj['Has 2-Way Auth?'];
    delete cleanedObj['hasCameraTop'];
    delete cleanedObj['hasCameraBack'];
    delete cleanedObj['hasCms'];
    delete cleanedObj['hasDispatch'];
    delete cleanedObj['hasInventory'];
    delete cleanedObj['hasSticker'];
    delete cleanedObj['_showDetails'];

    return cleanedObj;
}

export const CompanyUtil = {
    getDefaultCompanyObj,
    cleanupFields,
}