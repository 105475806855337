<template>
	<b-modal title="Company Location" :id="'company-location-view-' + company.id" :no-close-on-backdrop="true"
		@shown="refreshMap" size="lg" ok-only centered>
		<b-row>
			<b-col sm="12">
				<GmapMap :center="coordinates" :zoom="12" :style="{ width: '100%', height: '400px' }">
					<GmapMarker :position="coordinates" :clickable="true" @click="infoMarkerToggle = !infoMarkerToggle">
						<gmap-info-window @closeclick="closeInfoWindow(company)" :opened="infoMarkerToggle">
							Company Name: <b>{{ details.name }}</b>
							<br />
							Address: <b>{{ details.address }}</b>
						</gmap-info-window>
					</GmapMarker>
				</GmapMap>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import { LocationUtil } from '@/utils/locationUtil';

export default {
	name: 'company-location-view',
	props: {
		company: {
			type: Object,
			required: true,
		},
	},
	data() {
		return { infoMarkerToggle: false };
	},
	computed: {
		coordinates() {
			const coordinates = this.company.geoaddress;
			if (coordinates) {
				return {
					lat: LocationUtil.getLatitude(coordinates),
					lng: LocationUtil.getLongitude(coordinates),
				};
			} else return {};
		},
		details() {
			const details = this.company;
			return {
				name: details.name,
				address: details.address,
			};
		},
	},
	methods: {
		refreshMap() {
			this.$gmapDefaultResizeBus.$emit('resize');
			this.infoMarkerToggle = false;
		},
	},
};
</script>
